import { Typography } from '@mui/material'
import AuthWrapper from 'components/app/authWrapper'
import { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

const CalendarOdds = lazy(() => import('pages/calendar-odds'))
const BundesligaLeague = lazy(() => import('pages/leagues/bundesliga/league'))
const LaligaLeague = lazy(() => import('pages/leagues/laliga/league'))
const Liga1League = lazy(() => import('pages/leagues/liga-1/league'))
const Ligue1League = lazy(() => import('pages/leagues/ligue-1/league'))
const PremierLeagues = lazy(() => import('pages/leagues/premier-league/league'))
const SerieALeague = lazy(() => import('pages/leagues/serie-a/league'))
const MatchDetails = lazy(() => import('pages/match-details'))
const Match = lazy(() => import('pages/matches'))
const MatchesList = lazy(() => import('pages/matches-list'))
const MatchesListDetail = lazy(() => import('pages/matches-list-detail'))

const Loading = () => (
	<Typography variant="h6" component="p" align="center" color="gray">
		Loading...
	</Typography>
)

const Routing = () => {
	return (
		<BrowserRouter>
			<Suspense fallback={<Loading />}>
				<Routes>
					<Route path="/" element={<Match />} />
					<Route path="/calendar" element={<CalendarOdds />} />
					<Route path="/:id" element={<MatchDetails />} />
					<Route
						path="/championships"
						element={
							<AuthWrapper>
								<MatchesList />
							</AuthWrapper>
						}
					/>
					<Route
						path="/championships/:leagueId/:teamId"
						element={
							<AuthWrapper>
								<MatchesListDetail />
							</AuthWrapper>
						}
					/>
					<Route path="/leagues">
						<Route path="statistici-laliga" element={<LaligaLeague />} />
						<Route path="statistici-premier-league" element={<PremierLeagues />} />
						<Route path="statistici-bundesliga" element={<BundesligaLeague />} />
						<Route path="statistici-liga-1" element={<Liga1League />} />
						<Route path="statistici-ligue-1" element={<Ligue1League />} />
						<Route path="statistici-serie-a" element={<SerieALeague />} />
					</Route>
				</Routes>
			</Suspense>
		</BrowserRouter>
	)
}

export default Routing
