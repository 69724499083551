import React, { useState, useEffect, ReactNode } from 'react'
import { Box, Button, TextField, Typography, Paper, Avatar } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

interface AuthWrapperProps {
	children: ReactNode
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [authenticated, setAuthenticated] = useState(false)
	const [error, setError] = useState('')

	useEffect(() => {
		const storedTimestamp = localStorage.getItem('authTimestamp')
		if (storedTimestamp && new Date().getTime() < parseInt(storedTimestamp)) {
			setAuthenticated(true)
		}
	}, [])

	const handleLogin = () => {
		const hardcodedUsername = 'admin'
		const hardcodedPassword = 'QXWE#j#8#ePT7tSZ'

		if (username === hardcodedUsername && password === hardcodedPassword) {
			const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000 // 24 hours from now
			localStorage.setItem('authTimestamp', expirationTime.toString())
			setAuthenticated(true)
			setError('')
		} else {
			setError('Invalid username or password')
		}
	}

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		handleLogin()
	}

	if (authenticated) {
		return <>{children}</>
	}

	return (
		<Box
			sx={{
				height: '100vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: '#f5f5f5'
			}}>
			<Paper
				elevation={6}
				sx={{
					padding: '2rem',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					maxWidth: '400px',
					width: '100%'
				}}>
				<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Login Required
				</Typography>
				<Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
					<TextField
						margin="normal"
						fullWidth
						label="Username"
						value={username}
						onChange={e => setUsername(e.target.value)}
						autoComplete="username"
						autoFocus
					/>
					<TextField
						margin="normal"
						fullWidth
						label="Password"
						type="password"
						value={password}
						onChange={e => setPassword(e.target.value)}
						autoComplete="current-password"
					/>
					{error && <Typography color="error">{error}</Typography>}
					<Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
						Login
					</Button>
				</Box>
			</Paper>
		</Box>
	)
}

export default AuthWrapper
